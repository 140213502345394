import React from 'react';
import "../assets/css/contact.css";
import cont_logo from "../assets/image/bg1.png";
import Footer from './Footer';
import Header from './Header';

const Contact = () => {
  return (
   <>
   <Header/>
   <div className='sea_taxi_contact_page'>
    <div className='sea_taxi_page'>
            <div className='sea_taxi_contact_page_1'>
                <div className='sea_taxi_contact_pic_add'>
                    <img src={cont_logo} alt=""/>
                </div>
                <div className='sea_taxi_conts_input'>
                    <h1>Contact Us</h1>
                    <h2>Name</h2>
                    <input type="text" placeholder='Enter your Name'/>
                    <h2>Email</h2>
                    <input type="text" placeholder='Enter your Email'/>
                    <h2>Phone</h2>
                    <input type="text" placeholder='Enter your Phone Number'/>
                    <h2>Leave us a few words</h2>
                    <textarea type="text" placeholder='Type here ...'/>
                    <div className='contct_bts'>
                        <button>Submit</button>
                    </div>
                </div>
            </div>
            </div>
   </div>
   <Footer/>
   </>
  )
}

export default React.memo(Contact);