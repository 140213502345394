import React from "react";

const SingleCard = (props) => {
  const { title, totalNumber, icon } = props.item;
  const { onClick } = props; // Access onClick directly from props

  return (
    <div className="single__card" onClick={onClick}>
      <div className="card__content">
        <h4>{title}</h4>
        <span>{totalNumber}+</span>
      </div>

      <span className="card__icon">
        <i className={icon}></i> {/* Use className instead of class */}
      </span>
    </div>
  );
};

export default SingleCard;

