import React from "react";
import Router2 from "../../routes/Router2";
import Sidebar from "../Sidebar/Sidebarr";
import TopNav from "../TopNav/TopNav";


const Layout = () => {


  return (
    <div className="layout">
      <Sidebar />
      <div className="main__layout">
        <TopNav />
        <div className="content">
          <Router2 />
        </div>
      </div>
    </div>
  );
};

export default Layout;
