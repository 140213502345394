// ImageSlider.js
import React, { useState, useEffect } from "react";
import "./ImageSlider.css";

const ImageSlider = ({ images, isOpen, onClose, startIndex = 0 }) => {
  const [currentIndex, setCurrentIndex] = useState(startIndex);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    setCurrentIndex(startIndex);
  }, [startIndex]);

  const handleClick = (direction) => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 200); // Reset color after 200ms

    if (direction === "next") {
      setCurrentIndex((currentIndex + 1) % images.length);
    } else {
      setCurrentIndex(
        currentIndex === 0 ? images.length - 1 : currentIndex - 1
      );
    }
  };

  if (!isOpen) return null;

  return (
    <div className="slider-modal">
      <button onClick={onClose} className="slider-close">X</button>
      <div className="slider-content">
        <button
          onClick={() => handleClick("prev")}
          className={`slider-nav slider-prev ${isClicked ? "clicked" : ""}`}
        >
          ◀
        </button>
        <img src={images[currentIndex]} alt="Slider" className="slider-image" />
        <button
          onClick={() => handleClick("next")}
          className={`slider-nav slider-next ${isClicked ? "clicked" : ""}`}
        >
          ▶
        </button>
      </div>
    </div>
  );
};

export default ImageSlider;
