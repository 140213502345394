import React from "react";
import { BASE_URL } from '../../api/config';

const CarItem = (props) => {
  return (
    <div className="car__item" onClick={props.onClick}>
      <div className="car__item-top">
        <div className="car__item-tile">
          <h3>{props.car_type}</h3>
          <span>
          <p><i class="ri-steering-line"></i> {props.no_days} Days Trip</p>  
          </span>
        </div>

        <div className="car__item-tile">
        <p>{props.car_name}</p>
        <p style={{color: getIconColor(props.book_sts)}}>
          {props.book_sts === "0" ? (
                      <i className="ri-time-line"></i>
                    ) : props.book_sts === "200" ? (
                      <i className="ri-check-line"></i>
                    ) : props.book_sts === "300" ? (
                      <i className="ri-trophy-line"></i>
                    ) : props.book_sts === "404" ? (
                      <i className="ri-close-circle-line"></i>
                    ) : (
                      <i className="ri-error-warning-line"></i>
                    )} &nbsp;
           {props.book_sts === "0" ? 'Pending' : 
            props.book_sts === "200" ? 'Accepted' : 
            props.book_sts === "300" ? 'Finished' : 
            props.book_sts === "404" ? 'Canceled' : 'Unknown'}
          </p>
          </div>
      </div>

      <div className="car__img">
        <img style={{width:'100%',height:'100%',objectFit:'cover'}} src={`${BASE_URL}/${props.car_photo}`} alt="" />
      </div>

      <div className="car__item-bottom">
        <div className="car__bottom-left">
          {/* <p>
            <i class="ri-user-line"></i> {props.no_days}
          </p> */}
          <p>
            <i class="ri-repeat-line"></i>
            {props.start_place} 
            <br />
            {props.end_place}
          </p>
        </div>

       
        <p className="car__rent">
        ₹{props.price_per_km}/km <br />
        ₹{props.one_day_rent}/d</p>
      </div>
    </div>
  );
};

const getIconColor = (verifyStatus) => {
  switch (verifyStatus) {
    case "0":
      return "orange"; // Pending
    case "200":
      return "green"; // Accepted
    case "300":
      return "yellow"; // Finished
    case "404":
      return "red"; // Canceled
    default:
      return "gray"; // Unknown
  }
};

export default CarItem;
