import React, { useEffect, useState  } from 'react';
import axios from 'axios'; // Ensure axios is imported
import Swal from 'sweetalert2'; // Ensure SweetAlert2 is imported
import { BASE_URL } from '../../api/config';
import './login.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState(Array(4).fill('')); // Change the state to an array for 4 digits
    const [otpSent, setOtpSent] = useState(false);
    const [resendVisible, setResendVisible] = useState(false);
    const [timer, setTimer] = useState(60);



    useEffect(() => {
        let interval;
        if (otpSent && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setResendVisible(true);
        }
        
        return () => clearInterval(interval); 
    }, [otpSent, timer]);




    const validateMobileNumber = (number) => {
        const regex = /^[0-9]{10}$/;
        return regex.test(number);
    };

    const handleRequestOTP = async () => {
        if (validateMobileNumber(mobileNumber)) {
            try {
                const response = await axios.post(`${BASE_URL}/api/ADMINLOGINweb`, {
                    action: 'Login',
                    number: mobileNumber,
                });

                if (response.status === 200) {
                    Swal.fire({
                        title: 'OTP Sent!',
                        text: 'An OTP has been sent to your mobile number',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                    }).then(() => {
                        setOtpSent(true);
                        setTimer(60); 
                        setResendVisible(false);
                    });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: response.body,
                        icon: 'error',
                        timer: 2000,
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Invalid mobile number for sent OTP',
                    icon: 'error',
                    timer: 2000,
                });
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Please enter a valid 10-digit mobile number',
                icon: 'error',
                timer: 2000,
            });
        }
    };

    const handleVerifyOTP = async () => {
        const otpString = otp.join(''); // Join the array to create a string for the OTP
        try {
            const response = await axios.post(`${BASE_URL}/api/verifyOtp`, {
                number: mobileNumber,
                otp: otpString,
            });

            if (response.status === 200) {
                const { accessToken } = response.data;
                localStorage.setItem('Number', mobileNumber);
                sessionStorage.setItem('accessToken', accessToken);

                Swal.fire({
                    title: 'Success!',
                    text: response.data.message,
                    icon: 'success',
                    timer: 2000,
                }).then(() => {
                    navigate('/layout/dashboard'); 
                });

                fetchUserDtl(mobileNumber, otpString);
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Invalid OTP',
                icon: 'error',
                timer: 2000,
            });
        }
    };

    const fetchUserDtl = async (num, otp) => {
        const accessToken = sessionStorage.getItem("accessToken");
        try {
            const url = `${BASE_URL}/api/fetchUserByNumberAndOTP`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    number: num.toString(),
                    otp: otp.toString(),
                }),
            });

            if (!response.ok) {
                console.error("Failed to fetch user details:", response.statusText);
                return; 
            }
            const data = await response.json();
            sessionStorage.setItem('userDetails', JSON.stringify(data));
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    const handleOtpChange = (index, value) => {
        if (value.match(/^[0-9]?$/) !== null) { // Allow only numbers (0-9)
            const newOtp = [...otp];
            newOtp[index] = value; // Update the specific index
            setOtp(newOtp);
            if (value && index < otp.length - 1) {
                document.getElementById(`otp-${index + 1}`).focus(); // Move focus to the next input
            }
        }
    };

    return (
        <div className="Loginpage">
            <div className="logincontainer">
                <div className='imgseataxi'></div>
                <h2 className="title">Login</h2>

                <div className="formGroup">
                    <label htmlFor="mobile" className="loglabel"><i className="ri-phone-line"></i>&nbsp;&nbsp;Mobile Number</label>
                    <input
                        type="text"
                        id="mobile"
                        maxLength="10"
                        placeholder="Enter your mobile number"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        className="loginput"
                        autoFocus
                    />
                </div>

                {otpSent && (
                    <div className="formGroup"> 
                            <label className="loglabel"><i className="ri-lock-line"></i>&nbsp;&nbsp;OTP</label>
                    <div className="otp-inputs">
                        {otp.map((digit, index) => (
                            <input
                                type="text"
                                id={`otp-${index}`}
                                maxLength="1"
                                placeholder="-"
                                value={digit}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                className="loginput otp-digit"
                                key={index}
                            
                            />
                        ))}
                    </div>
                    </div>

                )}

                {/* Countdown Timer or Resend OTP Button */}
                {otpSent && (
                    !resendVisible ? (
                        <h5 className='resendbtntimr'>Resend OTP in {timer} seconds</h5>
                    ) : (
                        <h5 className='resendbtn' onClick={handleRequestOTP}>Resend OTP</h5>
                    )
                )}

                {!otpSent ? (
                    <button className="logbutton" onClick={handleRequestOTP}>
                        Send OTP
                    </button>
                ) : (
                    <button className="logbutton" onClick={handleVerifyOTP}>
                        Verify OTP
                    </button>
                )}
            </div>
        </div>
    );
};

export default Login;
