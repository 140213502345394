import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../api/config";
import "./viewstyle/viewbookingdtl.css";

const ViewBookingdtl = () => {
  const location = useLocation();
  const { bookorder } = location.state || {};

  const [isLoading, setIsLoading] = useState(true);


  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const dateStr = date.toLocaleDateString([], { year: 'numeric', month: 'long', day: '2-digit' });
    return `${dateStr}`;
  };


  useEffect(() => {
    console.log("bookorder", bookorder);
    setIsLoading(false);
    if (bookorder) {
    }
  }, [bookorder]);

  if (isLoading) return <center><div className="waitingspinner"></div></center>;
  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="viewbook-container">
          { bookorder ? (
            <>
              <div className="viewbook_activetitleTop">
                <h2 className="View__book-title">Booking Details</h2>
              </div>

              <div className="bookView__car-top">
                <div className="bookView__car-img">
                  <img src={`${BASE_URL}/${bookorder.car_photo}`} alt="" />
                </div>

                <div className="bookvehicle__history">
                  <h2>Booking Details</h2>
                  <div className="book-info">
                    <p>
                      <strong>Booking From:</strong> {bookorder.start_place}{" "}
                      &nbsp;
                    </p>
                    <p>
                      <strong>Booking To :</strong> {bookorder.end_place}
                    </p>
                    <p>
                      <strong>Start Date :</strong> {formatDateTime(bookorder.start_date)}
                    </p>
                    <p>
                      <strong>End Date : </strong> {formatDateTime(bookorder.end_date)}
                    </p>
                    <p>
                      <strong>Number Of Days : </strong> {bookorder.no_days}{" "}
                      Days
                    </p>
                    <p>
                      <strong>Kilometer : </strong> ₹ {bookorder.kms} kms
                    </p>
                    <p>
                      <strong>Total Amount : </strong> ₹ {bookorder.amt} /-
                    </p>
                    <p>
                      <strong>Booking Purpose : </strong> {bookorder.book_prps}
                    </p>
                    <p>
                      <strong>Booking Time : </strong> {formatDateTime(bookorder.dateTime)}
                    </p>
                    <p>
                      <strong>Current Place : </strong> {bookorder.cur_plc}
                    </p>
                  </div>
                </div>
              </div>

              {/* Vehicle Details Table */}

              <div className="bookvehicle-details">
                <div className="book-info">
                  <p>
                    <strong>Vehicle Name:</strong> {bookorder.car_name} &nbsp;
                    <span style={{ color: getColor(bookorder.vehAprove) }}>
                      (
                      {bookorder.vehAprove === 0
                        ? "Pending"
                        : bookorder.vehAprove === 1
                        ? "Active"
                        : bookorder.vehAprove === 2
                        ? "Deactive"
                        : "Unknown"}
                      )
                    </span>
                  </p>
                  <p>
                    <strong>Vehicle Number:</strong> {bookorder.car_number}
                  </p>
                  <p>
                    <strong>Cost per KM:</strong> ₹ {bookorder.price_per_km}
                  </p>
                  <p>
                    <strong>Cost per Day:</strong> ₹ {bookorder.one_day_rent}
                  </p>
                </div>

                <div className="subdiv">
                  <div className="bookdriver-details">
                    <p>
                      <strong>Driver Name:</strong> {bookorder.driver_name}
                    </p>
                    <p>
                      <strong>Driver Number:</strong> {bookorder.driver_number}
                    </p>
                    <p>
                      <strong>Experience:</strong> {bookorder.experience} years
                    </p>
                    <p>
                      <strong>Licence Number:</strong> {bookorder.licence_num}
                    </p>
                  </div>
                  <img
                    src={`${BASE_URL}/${bookorder.driver_photo_path}`}
                    alt={bookorder.driver_name}
                    className="bookdriver-photo"
                  />
                </div>

                <div className="book-info2">
                  <p>
                    <strong>Customer Name:</strong> {bookorder.log_name}
                  </p>
                  <p>
                    <strong>Customer Number:</strong> {bookorder.log_number}
                  </p>
                  <p>
                    <strong>Pick Time:</strong> {bookorder.pick_time}
                  </p>
                  <p className="subdiv2">
                    <strong>Current Status:</strong> &nbsp;
                    <h6
                      className="spend__place"
                      style={{ color: getStatusColor(bookorder.verify) }}
                    >
                      {bookorder.verify === "0"
                        ? "Pending"
                        : bookorder.verify === "200"
                        ? "Accepted"
                        : bookorder.verify === "300"
                        ? "Finished"
                        : bookorder.verify === "404"
                        ? "Canceled"
                        : "Unknown"}
                    </h6>
                  </p>
                </div>
              </div>

              
            </>
          ) : (
            <p className="headmargin">No Vehicle data available</p>
          )}
        </div>
      </div>
    </div>
  );
};

const getColor = (verifyStatus) => {
  switch (verifyStatus) {
    case 0:
      return "orange"; // Pending
    case 1:
      return "green"; // Accepted
    case 2:
      return "red"; // Canceled
    default:
      return "gray"; // Unknown
  }
};

const getStatusColor = (verifyStatus) => {
  switch (verifyStatus) {
    case "0":
      return "darkorange"; // Pending
    case "200":
      return "green"; // Accepted
    case "300":
      return "darkorange"; // Finished
    case "404":
      return "red"; // Canceled
    default:
      return "gray"; // Unknown
  }
};

export default ViewBookingdtl;
