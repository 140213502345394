/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import "../styles/allvehicledetails.css";
import { BASE_URL } from '../api/config.js';
import { useNavigate } from 'react-router-dom';

const AllVehicleDetails = () => {
  const [vehtypes, setVehicleTypes] = useState([]); 
  const [vehCateg, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]); 
  const [vehicles, setVehicle] = useState([]);
  const [filteredveh, setFilteredvehicle] = useState([]);
  const [category, setCategory] = useState("all");
  const [carId, setCarId] = useState(""); 
  const [carType, setCarType] = useState("all");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); 
  const [VehicleStatus, setVehicleStatus] = useState("active");

  useEffect(() => {
    const storedCarType = sessionStorage.getItem("carType") || "all";
    const storedCategory = sessionStorage.getItem("category") || "all";
    const VehicleStatus = sessionStorage.getItem("vehStatus") || "active";
    const storedCarId = sessionStorage.getItem("carId") || "";

    setCarType(storedCarType);
    setCategory(storedCategory);
    setVehicleStatus(VehicleStatus);
    setCarId(storedCarId);
    
  }, []);



  // Handle filter changes for car type
  const handleCarTypeChange = (event) => {
    const selectedValue = event.target.value;
    const [selectedId, selectedType] = selectedValue.split("|");
  
    setCarId(selectedId);
    setCarType(selectedType);
    sessionStorage.setItem("carType", selectedType);
    sessionStorage.setItem("carId", selectedId);
    sessionStorage.setItem("category", "all");

    // Filter categories based on selected car type
    if (selectedType !== 'all') {
      const filtered = vehCateg.filter(categ => categ.type_id == selectedId);
      setFilteredCategories(filtered);
    } else {
      setFilteredCategories(vehCateg); // Reset to all categories if 'all' is selected
    }
  
    setCategory("all");

    if (selectedType !== "all") {
      // eslint-disable-next-line eqeqeq
      const filteredVehicles = vehicles.filter(vehicle => vehicle.type_id == selectedId);
      setFilteredvehicle(filteredVehicles);
    } else {
      setFilteredvehicle(vehicles); // Show all vehicles if 'all' is selected
    }
  };

  
  // Handle category changes
  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);
    sessionStorage.setItem("category", selectedCategory);

    // Filter vehicles based on car type and category
    if (selectedCategory !== 'all') {
      const filteredVehicles = vehicles.filter(vehicle => vehicle.category === selectedCategory && (carType === 'all' || vehicle.type_id == carId));
      setFilteredvehicle(filteredVehicles);
    } else {
      // If category is 'all', only filter by car type
      const filteredVehicles = vehicles.filter(vehicle => (carType === 'all' || vehicle.type_id == carId));
      setFilteredvehicle(filteredVehicles);
    }
  };

  // Fetch vehicle types on component mount
  useEffect(() => {
    const fetchVehicleTypes = async () => {
      const accessToken = sessionStorage.getItem("accessToken");
      try {
        const response = await fetch(`${BASE_URL}/api/fetchvehType`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch vehicle types');
        }

        const data = await response.json();
        setVehicleTypes(data); // Set vehicle types to the state
      } catch (error) {
        console.error('Error fetching vehicle types:', error);
      }
    };
    fetchVehicleTypes();
  
  }, []);

  // Fetch vehicle categories
  useEffect(() => {
    const fetchCategories = async () => {
      const accessToken = sessionStorage.getItem("accessToken");
      try {
        const response = await fetch(`${BASE_URL}/api/fetchVehicleCategories`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }

        const data = await response.json();
        setCategories(data);
        setFilteredCategories(data); // Initialize filtered categories with all categories
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  // Fetch vehicles and apply filters on mount
 
    const fetchVehicles = async () => {
      const VehicleStatus = sessionStorage.getItem("vehStatus") || "active";
      const accessToken = sessionStorage.getItem("accessToken");
      try {

      let url ;
        if(VehicleStatus === 'active'){
            url = '/api/getTaxiEntries'
        }else if(VehicleStatus === 'deactive'){
            url = '/api/getDeactiveTaxiEntries'
        }else{
          return null;
        }
       
        const response = await fetch(`${BASE_URL}${url}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setVehicle(data);
        setFilteredvehicle(data); // Initially set filtered vehicles to all vehicles
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

  useEffect(() => {
    fetchVehicles();
  }, [VehicleStatus]);
  
  const handleVehStatusChange = (event) => {
    const selectedCategory = event.target.value;
    setVehicleStatus(selectedCategory);
    sessionStorage.setItem("vehStatus", selectedCategory);
    fetchVehicles();
  };


  // Apply filters whenever carType or category changes
  useEffect(() => {
    let filteredData = vehicles;

    // Filter by car type
    if (carType !== "all") {
      filteredData = filteredData.filter((vehicle) => vehicle.vehicle_type === carType);
    }
    // Filter by category
    if (category !== "all") {
      filteredData = filteredData.filter((vehicle) => vehicle.car_type === category);
    }

    setFilteredvehicle(filteredData);
  }, [carType, category, vehicles]);

  const handleView = (vehicle) => {
    navigate('/layout/view_vehicle_details', { state: { vehicle } });
    console.log('next page view_vehicle_details');
  };

  //if (loading) return <center><div className="waitingspinner"></div></center>;
  // if (error) return <p>Error: {error}</p>;

  return (
    <div className="allveh">
      <div className="allveh__wrapper">
        <div className="allveh__top">
          <h2 className="allveh__title">SeaTaxi Vehicle Details</h2>

          {/* Filter Widgets */}
          <div className="allvehfilter__widget-wrapper">
            <div className="allvehfilter__widget-01">
              <select value={`${carId}|${carType}`} onChange={handleCarTypeChange}>
                <option value={`${0}|${'all'}`}>--Select Type--</option>
                {vehtypes.map((type) => (
                  <option value={`${type.id}|${type.types}`} key={type.id}>
                    {type.types}
                  </option>
                ))}
              </select>
            </div>

            <div className="allvehfilter__widget-01">
              <select value={category} onChange={handleCategoryChange}>
                <option value="all">--Select Category--</option>
                {filteredCategories.map((categ) => (
                  <option value={categ.category} key={categ.id}>
                    {categ.category}
                  </option>
                ))}
              </select>
            </div>

            <div className="filter__widget-01">
              <select value={VehicleStatus} onChange={handleVehStatusChange}>
                <option value="active">Active</option>
                <option value="deactive">Deactive</option>
              </select> 
            </div>  

          </div>
        </div>

        {/* allveh Car List */}
        <div className="allveh__car-list">
          {
          loading?<div style={{minHeight: '50vh',minWidth:'75vw',display:'flex',textAlign:'center',alignItems:'center',justifyContent:'center'}}><center><div className="waitingspinner"></div></center></div>
          :(filteredveh.length > 0 ? (
            filteredveh.map((item) => (
              <div key={item.id} className="vehimgdiv">
                <img
                  src={`${BASE_URL}/${item.car_photo}`}
                  alt={`${item.car_name} img`}
                  className="allvehcar-photo"
                />
               <div className="vehnamediv">
               <h3 className="allvehcar-name">{item.car_name}</h3>
               <span className="info-icon" onClick={() => handleView(item)}>
                info
                <i className="ri-information-line"></i> {/* Remix Icon for info */}
              </span>
               </div>
              </div>
            ))
          ) : (
            <p style={{color:'red', textAlign:'center',fontSize:'14px'}}>No vehicles found.</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(AllVehicleDetails);
