import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../api/config'; 
import "./viewstyle/viewdealerdtl.css"
import ConfirmationModal from "../../components/Model/ConfirmationModal";
import ImagePopup from '../../components/reuseable/ImagePopup';
import Swal from 'sweetalert2'; 

const ViewDealerdtl = () => {
  const location = useLocation();
  const { dealer } = location.state || {};

  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isModalOpen, setModalOpen] = useState(false); // Modal state
  const [actionType, setActionType] = useState(''); // Action type for confirmation
  const [dealerToApprove, setDealerToApprove] = useState(null);

  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState('');

  useEffect(() => {
    if (dealer) {
      fetchDealerVehicleDetails();
      fetchDealerBookingDetails();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealer]);

  const fetchDealerVehicleDetails = async () => {
    try {
      const accessToken = sessionStorage.getItem("accessToken");
      const response = await axios.post(
        `${BASE_URL}/api/getDealerTaxiEntries`,
        { Logid: dealer.id },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setVehicleDetails(response.data);
    } catch (error) {
      console.error('Error fetching vehicle details:', error);
    }
  };

  const fetchDealerBookingDetails = async () => {
    try {
      const accessToken = sessionStorage.getItem("accessToken");
      const response = await axios.post(
        `${BASE_URL}/api/DealerOrderDtls`,
        { logId: dealer.id, logNum: dealer.usr_num },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setBookingDetails(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching booking details:', error);
      setIsLoading(false);
    }
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const dateStr = date.toLocaleDateString([], { year: 'numeric', month: 'long', day: '2-digit' });
    return `${dateStr}`;
  };

  const formatDateTimeNew = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long', // 'short' for abbreviated month name
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Change to false for 24-hour format
    };
  
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options); // Adjust 'en-US' as needed
  };


  const openModal = (id, numbr, verify) => {
    setActionType(verify === 1 ? 'Activate' : verify === 2 ? 'Deactivate' : 'Approve');
    setDealerToApprove({ id, numbr, verify });
    setModalOpen(true);
  };

  const handleAdminApprove = async () => {
    if (!dealerToApprove) return;

    const { id, numbr, verify } = dealerToApprove;
    const accessToken = sessionStorage.getItem('accessToken');
    console.log('dealerToApprove',dealerToApprove);
    
    try {
      const response = await axios.post(
        `${BASE_URL}/api/ADMIN_Approve_Dealer`,
        {
          id: id.toString(),
          numbr: numbr.toString(),
          verify: verify,
          Del_id: id.toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const message = response.data;
        console.log("Message:", message);

        if (verify === 1) {

            await Swal.fire({
              title: 'Dealer Reactivated',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false,
              customClass: {
                popup: 'custom-popup',
                title: 'custom-title',
                content: 'custom-content'
              }
            });
    
            window.history.back();

        } else if (verify === 2) {
          await axios.post(
            `${BASE_URL}/api/updateRejectReason`,
            {
              action: "DealerReject",
              id: id.toString(),
              tokn: numbr.toString(),
              dealid: id.toString(),
              rej_rsn: "Your Rejection Reason",
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          await Swal.fire({
            title: 'Dealer Deactivated',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
            customClass: {
              popup: 'custom-popup',
              title: 'custom-title',
              content: 'custom-content'
            }
          });
  
          window.history.back();
        }

      } else {
        alert("Action failed");
      }
    } catch (error) {
      console.error("Error in ADMIN approve function:", error);
    }

    setModalOpen(false); // Close the modal after the action is taken
  };

  const openImagePopup = (src) => {
    setPopupImageSrc(src);
    setImagePopupOpen(true);
  };

  const closeImagePopup = () => {
    setImagePopupOpen(false);
  };
  if (isLoading) return <center><div className="waitingspinner"></div></center>;


  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="dealer-details-container">
          {dealer ? (
            <>
              <div className='activetitleTop'>
              <h2 className='headmargin'>{dealer.usr_name}'s Details</h2>
              
              <button
  className="activeDeactive_btn"
  style={{ backgroundColor: getbtnColor(dealer.Ad_Verify) }}
  onClick={() => openModal(dealer.id, dealer.usr_num, 
    dealer.Ad_Verify === 1 
    ? 2 
    : dealer.Ad_Verify === 2 
    ? 1 
    : dealer.Ad_Verify === 0 
    ? 1 
    : 0)}
>
  {dealer.Ad_Verify === 1
    ? "Deactivate"
    : dealer.Ad_Verify === 2
    ? "Activate"
    : dealer.Ad_Verify === 0
    ? "Approve"
    : "Unknown"}
</button>
              </div>
              {/* Dealer Information Table */}
              <table className="dealer-info-table">
                <thead>
                  <tr>
                    <th>Profile Photo</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Phone Number</th>
                    <th>Pincode</th>
                    <th>ID Proof</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                     

<img
  src={dealer.usr_profile ? `${BASE_URL}/${dealer.usr_profile}` : require('../../assets/images/profile-02.png')}
  alt={`${dealer.usr_name} Profile`}
  className="dlrprofile-photo"
  onClick={() => dealer.usr_profile && openImagePopup(`${BASE_URL}/${dealer.usr_profile}`)}
/>
                    </td>
                    <td>{dealer.usr_name}</td>
                    <td>{dealer.address}</td>
                    <td>{dealer.usr_num}</td>
                    <td>{dealer.pincode}</td>
                    <td>

                    <img
                          src={dealer.id_proof && dealer.id_proof.trim() !== "" ? `${BASE_URL}/${dealer.id_proof}` : require('../../assets/images/no_img.jpg')}
                          alt={`${dealer.usr_name} Document`}
                          className="dlrid-proof-photo"
                          onClick={() => dealer.id_proof && dealer.id_proof.trim() !== "" && openImagePopup(`${BASE_URL}/${dealer.id_proof}`)}
                        />


                    </td>
                    <td style={{ color: getColor(dealer.Ad_Verify) }}>{
                    dealer.Ad_Verify === 0 ? 'Pending' : dealer.Ad_Verify === 1 ? 'Active' :dealer.Ad_Verify === 2 ? 'Deactive' : 'Unknown'}</td>
                  </tr>
                </tbody>
              </table>

              {/* Vehicle Details Table */}
              <h3 className='headmargin'>Vehicle Details</h3>
              {vehicleDetails.length > 0 ? (
                <table className="vehicle-details-table">
                  <thead>
                    <tr>
                    <th>Vehicle Id</th>
                    <th>Vehicle Photo</th>
                    <th>Vehicle Name</th>
                    <th>Vehicle Number</th>
                    <th>Vehicle Laocate</th>
                    <th>Per /km</th>
                    <th>Per /day</th>
                    <th>Driver / licence photo</th>
                    <th>Status</th>
                    
                      {/* Add more columns as needed */}
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleDetails.map((vehicle, index) => (
                      <tr key={index}>
                        <td>{vehicle.id}</td>
                        <td>
                        <img
                          src={`${BASE_URL}/${vehicle.car_photo}`}
                          alt={`${vehicle.car_name} pic`}
                          className="vh-photo"
                          onClick={() => openImagePopup(`${BASE_URL}/${vehicle.car_photo}`)}
                        />
                      </td>
                      <td>{vehicle.car_name}</td>
                      <td>{vehicle.car_number}</td>
                      <td>{vehicle.veh_locate}</td>
                      <td>₹ {vehicle.price_per_km} /km</td>
                      <td>₹ {vehicle.one_day_rent} /day</td>
                      <td>
                      <img
                          src={`${BASE_URL}/${vehicle.driver_photo_path}`}
                          alt={`DriverImage`}
                          className="vh-proof-photo"
                          onClick={() => openImagePopup(`${BASE_URL}/${vehicle.driver_photo_path}`)}
                        />
                        <img
                          src={`${BASE_URL}/${vehicle.license_path}`}
                          alt={`${vehicle.car_name} Document`}
                          className="vh-proof-photo"
                          onClick={() => openImagePopup(`${BASE_URL}/${vehicle.license_path}`)}
                        />
                      </td>
                      <td style={{ color: getColor(vehicle.vehAprove) }}>{vehicle.vehAprove === 0 ? 'Pending' :vehicle.vehAprove === 1 ? 'Active' :vehicle.vehAprove === 2 ?'Rejected' :'Unknown'}</td>
                        {/* Add more vehicle details as needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className='headmargin emptymsgstyle'>No vehicle details available.</p>
              )}

              {/* Booking Details Table */}
              <h3 className='headmargin'>Booking Details</h3>
              {bookingDetails.length > 0 ? (
                <table className="booking-details-table">
                  <thead>
                    <tr>
                      <th>Booking ID</th>
                      <th>Customer Name</th>
                      <th>Customer Number</th>
                      <th>Veh Photo</th>
                      <th>Trip Place from / to</th>
                      <th>Trip Date from / to</th>
                      <th>Dealer Number</th>
                      <th>Booking date</th>
                      <th>Booking Status</th>
                      {/* Add more columns as needed */}
                    </tr>
                  </thead>
                  <tbody>
                    {bookingDetails.map((booking, index) => (
                      <tr key={index}>
                        <td>{booking.bookid}</td>
                        <td>{booking.log_name}</td>
                        <td>{booking.log_number}</td>
                        <td>
                        <img
                          src={`${BASE_URL}/${booking.car_photo}`}
                          alt={`${booking.car_name} pic`}
                          className="vh-photo"
                          onClick={() => openImagePopup(`${BASE_URL}/${booking.car_photo}`)}
                        />
                      </td>
                        <td>{booking.start_place}<br />{booking.end_place}</td>
                        <td>{formatDateTime(booking.start_date)}<br />{formatDateTime(booking.end_date)}</td>
                        <td>{booking.Del_num}</td>
                        <td>{formatDateTimeNew(booking.dateTime)}</td>
                        <td>
                        <h6 className="spend__place" style={{ color: getStatusColor(booking.verify) }}>
                          {booking.verify === "0" ? 'Pending' : 
                          booking.verify === "200" ? 'Accepted' : 
                          booking.verify === "300" ? 'Finished' : 
                          booking.verify === "404" ? 'Canceled' : 'Unknown'}
                        </h6>
                        </td>
                        {/* Add more booking details as needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className='headmargin emptymsgstyle'>No booking details available.</p>
              )}

<ConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleAdminApprove}
                message={`Are you sure you want to ${actionType} this dealer?`}
              />

{imagePopupOpen && (
  <ImagePopup 
    src={popupImageSrc} 
    alt="Vehicle" 
    onClose={closeImagePopup} 
  />
)}
            </>
          ) : (
            <p className='headmargin'>No dealer data available</p>
          )}
        </div>
      </div>
      
    </div>
  );
};

const getColor = (verifyStatus) => {
  switch (verifyStatus) {
    case 0:
      return "orange"; // Pending
    case 1:
      return "green"; // Accepted
    case 2:
      return "red"; // Canceled
    default:
      return "gray"; // Unknown
  }
};


const getStatusColor = (verifyStatus) => {
  switch (verifyStatus) {
    case "0":
      return "orange"; // Pending
    case "200":
      return "green"; // Accepted
    case "300":
      return "blue"; // Finished
    case "404":
      return "red"; // Canceled
    default:
      return "gray"; // Unknown
  }
};

const getbtnColor = (verifyStatus) => {
  switch (verifyStatus) {
    case 0:
      return "orange"; // Pending
    case 2:
      return "darkgreen"; // Accepted
    case 1:
      return "darkred"; // Finished
    default:
      return "gray"; // Unknown
  }
};
export default ViewDealerdtl;
