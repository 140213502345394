import React from "react";

const MyOrder = () => {
  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div>
          <p style={{ color: "red" }}>
            <center>My Order</center>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MyOrder;
