/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "../styles/seataxibookings.css";
import { BASE_URL } from '../api/config.js';
import CarItem from "../components/UI/CarItem.js";
import { useNavigate } from "react-router-dom";

const Bookings = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState("All");
  const [carType, setCarType] = useState("New");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const storedOrderStatus = sessionStorage.getItem("ownBookStatus") || "All";
    setOrderStatus(storedOrderStatus); // Set the saved order status or default to "All"
  }, []);

  // Handle filter changes for both car type and order status
  const handleOrderStatusChange = (event) => {
    const selectedCategory = event.target.value;
    setOrderStatus(selectedCategory);
    sessionStorage.setItem("ownBookStatus", selectedCategory);
  };

  const handleCarTypeChange = (event) => {
    setCarType(event.target.value);
  };

  // Fetch orders and apply filters
  useEffect(() => {
    const fetchOrders = async () => {
      const accessToken = sessionStorage.getItem("accessToken");
      const userDetailsString = sessionStorage.getItem("userDetails");
      const userDetails = JSON.parse(userDetailsString);
      try {
        const url = `${BASE_URL}/api/AllBookingDetailsADWEB/${userDetails.id}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setOrders(data);
        setFilteredOrders(data); // Initially set filtered orders to all orders
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Apply filters whenever carType or orderStatus changes
  useEffect(() => {
    let filteredData = orders;

    // Filter by car type
    // if (carType) {
    //   filteredData = filteredData.filter((order) => order.car_type === carType);
    // }

    // Filter by order status
    if (orderStatus !== "All") {
      if (orderStatus === "Pending") {
        filteredData = filteredData.filter((order) => order.verify === "0");
      } else if (orderStatus === "Accepted") {
        filteredData = filteredData.filter((order) => order.verify === "200");
      } else if (orderStatus === "Finished") {
        filteredData = filteredData.filter((order) => order.verify === "300");
      } else if (orderStatus === "Canceled") {
        filteredData = filteredData.filter((order) => order.verify === "404");
      }
    }

    setFilteredOrders(filteredData);
  }, [carType, orderStatus, orders]);


  const handleView = (bookorder) => {
    sessionStorage.setItem("ownBookStatus", orderStatus); // Save the current category before navigating
    navigate('/layout/view_booking_details', { state: { bookorder } });
    console.log('next page view_booking_details',bookorder);
  };

  if (loading) return <center><div className="waitingspinner"></div></center>;
  if (error) return <p>Error: {error}</p>;
  
  return (
    <div className="bookings">
      <div className="booking__wrapper">
      <div className="booking__top">
        <h2 className="booking__title">SeaTaxi Booking Details</h2>
  
        {/* Filter Widgets */}
        <div className="filter__widget-wrapper">
          <div className="filter__widget-01">
            {/* <select value={carType} onChange={handleCarTypeChange}>
              <option value="New">New</option>
              <option value="Popular">Popular</option>
              <option value="Upcoming">Upcoming</option>
            </select> */}
          </div>

          <div className="filter__widget-01">
          <select value={orderStatus} onChange={handleOrderStatusChange}>
              <option value="All">All Orders</option>
              <option value="Pending">Pending Orders</option>
              <option value="Accepted">Accepted Orders</option>
              <option value="Canceled">Canceled Orders</option>
              <option value="Finished">Finished Orders</option>
            </select>
          </div>
        </div>
      </div>
        {/* Booking Car List */}
        {/* <div className="booking__car-list">
          {carData?.map((item) => (
            <CarItem item={item} key={item.id} />
          ))}
        </div> */}

        <div className="booking__car-list">
          {filteredOrders?.map((item) => (
          <CarItem 
          key={item.bookid}
          car_type={item.car_type}
          car_name={item.car_name}
          car_photo={item.car_photo}
          no_days={item.no_days}
          start_place={item.start_place}
          price_per_km={item.price_per_km}
          one_day_rent={item.one_day_rent}
          end_place={item.end_place}
          book_sts={item.verify}
          onClick={() => handleView(item)} 
        />
          ))}
        </div>

        {filteredOrders.length > 0 ? (
          <p></p>
            ) : (
              <center className='vehno--data'><p>No Bookings found...!</p></center>
            )}

        {/* <div>
            {
            filteredOrders.map(car => (
              <CarItem item={car} key={car.bookid} />
            ))
            }
        </div> */}

      </div>
    </div>
  );
};

export default Bookings;
