import React from 'react';
import './imagepopup.css'; // Ensure this CSS file exists

const ImagePopup = ({ src, alt, onClose }) => {
  return (
    <div className="image-popup" onClick={onClose}>
      <div className="image-popup-content">
        <img src={src} alt={alt} />
       
        <button className="imgclose-button" onClick={onClose}>
          <i className="ri-close-fill " ></i>
        </button>

      </div>
    </div>
  );
};

export default ImagePopup;

