import React, { useEffect, useState } from 'react';
import './ConfirmationModal.css'; // Include your modal styles here

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setAnimate(true);
      const timer = setTimeout(() => setAnimate(false), 500); // Reset animation after 0.5s
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <i className={`ri-error-warning-line ${animate ? 'popscale-up' : ''}`}></i>
        <h3>Confirmation</h3>
        <p>{message}</p>
        <div>
          <button onClick={onConfirm}>Confirm</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
