// src/components/DealerForm.js

import React, { useState } from 'react';
import { BASE_URL } from '../../api/config'; 
import { FaUser, FaPhone, FaMapMarkerAlt, FaCode } from 'react-icons/fa'; // Importing icons
import Swal from 'sweetalert2'; 

const DealerForm = ({ onClose }) => {

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    profProof: null,
    usr_name: '',
    usr_num: '',
    address: '',
    pincode: '',
    status: 'individual', // Default status
    idProof: null, // For the ID proof file
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleprofileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      profProof: e.target.files[0], // Store the file object
    }));
  };
  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      idProof: e.target.files[0], // Store the file object
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const accessToken = sessionStorage.getItem("accessToken");

    const formDataToSubmit = new FormData();

    if (formData.profProof) {
      formDataToSubmit.append('prof_pic', formData.profProof); // Append the ID proof file
    }
    // Append all form data
    formDataToSubmit.append('who_is', 'Dealer'); // Adjust 'who_is' based on your logic
    formDataToSubmit.append('usr_name', formData.usr_name);
    formDataToSubmit.append('usr_num', formData.usr_num);
    formDataToSubmit.append('address', formData.address);
    formDataToSubmit.append('pincode', formData.pincode);
    formDataToSubmit.append('del_sts', formData.status);
   
    if (formData.idProof) {
      formDataToSubmit.append('id_prf', formData.idProof); // Append the ID proof file
    }
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 300000);
    try {
      const response = await fetch(`${BASE_URL}/api/RegisterDealerWeb`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formDataToSubmit,
        signal: controller.signal,
      });
      clearTimeout(timeoutId);
      if (response.status === 400) {
        const result = await response.json();
        Swal.fire({
          title: result.msg, 
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
                popup: 'custom-popup',    // Class for the alert popup
                title: 'custom-title',    // Class for the title
                content: 'custom-content' // Class for the content text
            }
        });
       }

      if (response.status===200) {
      // Optionally, you can clear the form or show a success message
      //alert('Dealer added successfully');
      Swal.fire({
        title: 'Dealer added successfully!',
        html: '<p class="custom-subtitle">The dealer will appear in the Dealer list once their OTP is verified.</p>',
        icon: 'success',
        
            showConfirmButton: true,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
      }).then(()=>{
        onClose();
      });
    }
      // Close the popup after submission
    } catch (error) { 
      if (error.name === 'AbortError') {
        console.log('Request timed out');
      }
      console.error('Error:', error);
      alert(error.message);
    }finally{
      setLoading(false);
    }
  };

  return (
    <div className="dealer-form-popup">
      <h2>Add Dealer</h2>
      <form onSubmit={handleSubmit}>

      <div>
          <label>
            <strong>Dealer profile:</strong>
            <input
              type="file"
              name="idProof"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" // Acceptable file types
              onChange={handleprofileChange}
              required
            />
          </label>
        </div>


        <div>
          <label>
            <FaUser /> {/* Dealer Name Icon */}
            <input
              type="text"
              name="usr_name"
              placeholder="Dealer Name"
              value={formData.usr_name}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            <FaPhone /> {/* Phone Number Icon */}
            <input
              type="text"
              name="usr_num"
              placeholder="Phone Number"
              value={formData.usr_num}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            <FaMapMarkerAlt /> {/* Address Icon */}
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            <FaCode /> {/* Pincode Icon */}
            <input
              type="text"
              name="pincode"
              placeholder="Pincode"
              value={formData.pincode}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        
        <div>
          <label>
            <strong>Dealer Status:</strong>
          </label>
          <div>
            <label>
              <input
                type="radio"
                name="status"
                value="individual"
                checked={formData.status === 'individual'}
                onChange={handleChange}
              />
              Individual
            </label>
            <label>
              <input
                type="radio"
                name="status"
                value="travels"
                checked={formData.status === 'travels'}
                onChange={handleChange}
              />
              Travels
            </label>
          </div>
        </div>

        <div>
          <label>
            <strong>ID Proof:</strong>
            <input
              type="file"
              name="idProof"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" // Acceptable file types
              onChange={handleFileChange}
              required
            />
          </label>
        </div>

        <div className='dealer-form-foot'>
        {loading ?
              <p className='loadingCircularspinner'></p>
            : <button type="submit">Register Dealer</button>
          }

            <button type="button" onClick={onClose}>Cancel</button>
        </div>

      </form>
    </div>
  );
};

export default DealerForm;
